import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import style from "./error.module.scss";

export default function Error({ error }) {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const element = (
    <div className={style.error}>
        <h2 className={style.error_title}>{error}</h2>
    </div>
  );

  return !isSSR && ReactDOM.createPortal(element, document.getElementById("popup"));
}
