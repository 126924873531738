import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'

function Layout({children, handleSidebarLinkClick, defaultActiveKey}) {
  return (
    <>
      <Header handleSidebarLinkClick={handleSidebarLinkClick} defaultActiveKey={defaultActiveKey} />
         {children}
      <Footer />
    </>
  )
}

export default Layout