import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const Content = lazy(() => import("./components/content/Content"));
const Contact = lazy(() => import("./components/contact/Contact"));



const SoftwareDevelopment = () => {
  return (
    <>
      <Banner />
      <Content />
      <Contact />
    </>
  );
};

export default SoftwareDevelopment;
