import { lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./contactUs.module.scss";
import ContactForm from "./components/contactForm/ContactForm";
const Addresses = lazy(() => import("./components/addresses/Addresses"));



const ContactUs = () => {


  return (
    <section className={style.contactUs}>
      <Container>
      <Row>
        <Col md={6}>
        <Addresses />
        </Col>
        <Col md={6}>
          <ContactForm />
        </Col>
      </Row>
      </Container>

    </section>
  
    
  );
};

export default ContactUs;
