import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const Startup = lazy(() => import("./components/startup/Startup"));
const Portfolio = lazy(() => import("./components/portfolio/Portfolio"));
const Boxes = lazy(() => import("./components/boxes/Boxes"));
const Contact = lazy(() => import("./components/contact/Contact"));


const InvestingStartups = () => {


  
  return (
    <> 
      <Banner />
      <Startup />
      <Boxes />
      <Portfolio />
      <Contact />
    </>
  );
};

export default InvestingStartups;
