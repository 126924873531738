import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MainBanner from "../../../../../components/mainBanner/MainBanner";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataBanner } from "../../../../../app/solutions/corporates/corporatesSlice";
import { useTranslation } from "react-i18next";
 
function Banner() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, banner, error } = useSelector((state) => state.corporates);
  useEffect(() => {
    dispatch(getDataBanner());
  }, []);

  // change language handler
  const { i18n } = useTranslation();
  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <section className="banner">
      <MainBanner
        background={banner?.result?.image}
        title={`${i18n.language === "en" ? banner?.result?.titleEn : banner?.result?.titleAr }`}
        subTitle={`${i18n.language === "en" ? banner?.result?.descriptionEn : banner?.result?.descriptionAr }`}
      />
    </section>
  );
}

export default Banner;
