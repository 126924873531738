import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";

import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataProjects } from "../../../../../app/about/socialImpact/socialImpactSlice";
import { useTranslation } from "react-i18next";
import Title from "../../../../../components/title/Title";
import SecondCard from "../../../../../components/cards/secondCard/SecondCard";

function Projects() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, projects, error } = useSelector((state) => state.socialImpact);
  useEffect(() => {
    dispatch(getDataProjects());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // errors
  if (error) {
    return <Error error={error.message} />;
  }


  return (
    <section className="projects pb-5 mb-4">
      <Container>
        <Title title={t("ourSustainabilityProjects")} className="mainColor" />
        <Row>
          {projects?.result?.map((card) => {
            return (
              <Col lg={4} md={6} className="mb-4" key={card.id}>
                <SecondCard
                  title={`${i18n.language === "en"
                    ? card.titleEn
                    : card.titleAr
                    }`}
                  desc={`${i18n.language === "en"
                    ? card.descriptionEn
                    : card.descriptionAr
                    }`}
                  img={card.image}
                  link={`/about/social-impact/projects/${card.id}`}
                  linkText={t("readMore")}
                  id={card.id}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default Projects;
