import React from "react";
import ContactUsSection2 from "../../../../../../components/contactUs2/ContactUs2";
import { useTranslation } from "react-i18next";

function Contact2() {
  const { t } = useTranslation();

  return (
    <section className="contctUs pb-5">
      <ContactUsSection2 ios="https://apps.apple.com/tr/app/qessatie/id1574335162" android="https://play.google.com/store/apps/details?id=com.nahdetmisr.kidsapp&hl=en&gl=US"
        contact2={true} desc={t("DownloadQessatieAppNowOrVisit")} download={true} />
    </section>
  );
}

function Contact3() {
  const { t } = useTranslation();

  return (
    <section className="contctUs pb-5">
      <ContactUsSection2 className="d-lg-flex text-lg-start text-center" green={true} ios="https://apps.apple.com/fr/app/rehlatie-%D8%B1%D8%AD%D9%84%D8%AA%D9%8A/id1639897493" android="https://play.google.com/store/apps/details?id=com.nahdetmisr.rehlatie&hl=en&gl=US"
        contact2={true} desc={t("DownloadRehlatieAppNowOrVisit")} download={true} />
    </section>
  );
}
function Contact4() {
  const { t } = useTranslation();

  return (
    <section className="contctUs pb-0 pt-4">
      <ContactUsSection2 className="d-lg-flex text-lg-start text-center" darkGreen={true} linkText={t("Website")} ios="https://apps.apple.com/eg/app/aladwaa-education/id1540942437" android="https://play.google.com/store/apps/details?id=com.nahdetmisr.adwaa&hl=en&gl=US"
        link={"https://www.aladwaa.com/"} contact2={true} desc={t("DownloadAlAdwaaAppNowOrVisitOur1")} desc3={t("DownloadAlAdwaaAppNowOrVisitOur2")} download={true} />
    </section>
  );
}



export { Contact2, Contact3, Contact4 };
