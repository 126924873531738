import { lazy } from "react";
const WhatWeDo = lazy(() => import("./components/whatWeDo/WhatWeDo"));
const OurPartners = lazy(() => import("./components/ourPartners/OurPartners"));
const News = lazy(() => import("./components/news/News"));
const WhoWeAre = lazy(() => import("./components/whoWeAre/WhoWeAre"));
const Banner = lazy(() => import("./components/banner/Banner"));
const LatestAdditions = lazy(() => import("./components/latestAdditions/LatestAdditions"));
const LatestProjects = lazy(() => import("./components/latestProjects/LatestProjects"));
const WhoWeServe = lazy(() => import("./components/whoWeServe/WhoWeServe"));

const Home = () => {
  return (
    <>
      <Banner />
      <WhoWeAre />
      <WhatWeDo />
      <WhoWeServe />
      <LatestProjects />
      <LatestAdditions />
      <News />
      <OurPartners />
    </>
  );
};

export default Home;
