import React from "react";
import style from "./contactUs.module.scss";
import { useTranslation } from "react-i18next";
import ContactButton from "../utils/links/ContactButton";
import { Fade } from "react-reveal";

function ContactUsSection({
  linkText,
  link,
  desc,
  contact,
  orange,
  phoneNumber1,
  phoneNumber2,
  phoneNumberDash,
  desc2,
  green,
  moreText
}) {
  // change language handler
  const { t } = useTranslation();
  return (
    <div className={`${style.contctUsSection} ${orange && `d-block ${style.orange}`} ${green && `d-block ${style.green}`} ${moreText && style.cust}`}>
      {link ? (
        <div className="d-lg-flex gap-2">
          <Fade left duration={2500}>
            <p className={style.contctUsSection_desc}>{desc}</p>
          </Fade>
          <a href={link} target="_blank" className={style.contctUsSection_link}>
            {linkText}
          </a>
        </div>
      ) : (
        <Fade left duration={2500}>
          <p className={style.contctUsSection_desc}>{desc}</p>
        </Fade>
      )}
      {orange && (
        <Fade left duration={2500}>
          <div className="d-md-flex mt-2 gap-1">
            <p className={`mb-md-0 mb-1 ${style.contctUsSection_desc}`}>{desc2}</p>
            {phoneNumber1 && (
              <div className="d-flex gap-2">
                <a href={`tel:${+phoneNumber1}`} className={style.contctUsSection_phoneNumber}>
                  {phoneNumberDash}
                </a>
                <a href={`tel:${+phoneNumber2}`} className={style.contctUsSection_phoneNumber}>
                  {phoneNumber2}
                </a>
              </div>
            )}
          </div>
        </Fade>
      )}
      {contact && (
        <Fade right duration={2500}>
          <ContactButton to="/contact-us">{t("contact")}</ContactButton>
        </Fade>
      )}
    </div>
  );
}

export default ContactUsSection;
