import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./footer.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Img from "../../utils/img/Img";

function Footer() {
  // change language handler
  const { t, i18n } = useTranslation();

  return (
    <footer className={`${i18n.language === "en" ? style.footer : `${style.footer} ${style.footerAr}`}`}>
      <Container>
        <Row>
          <Col lg={3} xs={6} className="mb-lg-0 mb-5">
            <div className={style.footer_logo}>
              <Img src="/footerLogo.png" alt="" />
            </div>
          </Col>
          <Col lg={3} xs={6} className="mb-lg-0 mb-5">
            <div className={style.footer_col}>
              <h4 className={style.footer_col_title}>{t("about")}</h4>
              <ul className={`list-unstyled ${style.footer_col_list}`}>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/about/group-overview">
                    {t("groupOverview")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/about/subsidiaries">
                    {t("subsidiaries")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <a
                    href="https://nahdetmisr.zohorecruit.com/jobs/Careers"
                    className={style.footer_col_list_item_link}
                    target="_blank"
                  >
                    {t("careers")}
                  </a>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/contact-us">
                    {t("contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4} xs={6}>
            <div className={style.footer_col}>
              <h4 className={style.footer_col_title}>{t("solutions")}</h4>
              <ul className={`list-unstyled ${style.footer_col_list}`}>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/solutions/k-12">
                    {t("k12")}
                  </Link>
                  
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/solutions/higher-education">
                    {t("HigherEducation")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="solutions/vocational-education">
                    {t("vocational")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="solutions/teaching">
                    {t("TeachingArabicForeignLanguage")}
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={2} xs={6}>
            <div className={style.footer_col}>
              <h4 className={`invisible ${style.footer_col_title}`}>{t("solutions")}</h4>
              <ul className={`list-unstyled ${style.footer_col_list}`}>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/solutions/corporates">
                    {t("Corporates")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="/solutions/investing-startups">
                    {t("InvestingEdtechStartups")}
                  </Link>
                </li>
                <li className={style.footer_col_list_item}>
                  <Link className={style.footer_col_list_item_link} to="solutions/publishing">
                    {t("publishing")}
                  </Link>
                </li>
              </ul>
              <ul className={`list-unstyled ${style.footer_col_social}`}>
                {/* <li className={style.footer_col_social_item}>
                  <Link className={style.footer_col_social_item_link} to="/" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Link>
                </li>
                <li className={style.footer_col_social_item}>
                  <Link className={style.footer_col_social_item_link} to="/" target="_blank">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </Link>
                </li>
                <li className={style.footer_col_social_item}>
                  <Link className={style.footer_col_social_item_link} to="/" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li> */}
                <li className={style.footer_col_social_item}>
                  <Link className={style.footer_col_social_item_link} to="https://www.linkedin.com/company/nahdet-misr-publishing-group/posts/?feedView=all" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className={style.footer_copyright}>
          <Row>
            <Col md={6}>
              <p className={style.footer_copyright_leftParagraph}>{t("copyright")}</p>
            </Col>
            <Col md={6}>
              <p className={style.footer_copyright_rightParagraph}>
                <span className={style.footer_copyright_rightParagraph_desc}>{t("icon")}</span>
                <Link to="https://icon-creations.com/" target="_blank">
                  <Img src="/iconCreations.png" alt="" />
                </Link>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
