import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const InternationalAcademyContent = lazy(() => import("./components/InternationalAcademyContent/InternationalAcademyContent"));

const InternationalAcademy = () => {


  return (
    <> 
      <Banner />
      <InternationalAcademyContent />
    </>
  );
};

export default InternationalAcademy;
