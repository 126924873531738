import React from "react";
import style from "./stepsCard.module.scss";
import Img from "../../utils/img/Img";


function StepsCard({ img, number, title }) {
  return (
      <div className={`h-100 ${style.step} ${number === 1 && style.red} ${number === 2 && style.yellow} ${number === 3 && style.green} ${number === 4 && style.darkRed}`}>
        <div className="d-flex align-items-center mb-3">
          <p className={style.step_number}>{number}</p>
          <h4 className={style.step_title}>{title}</h4>
        </div>
        <Img src={img} alt={title} className={style.step_img} />
      </div>

  );
}

export default StepsCard;
