import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("services/capabilityBuilding/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/CapabilityBuilding/capabilityBuildingHeadSectionHeadSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataCapability = createAsyncThunk("services/capabilityBuilding/getDataCapability", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/CapabilityBuilding/GetServiceCategories");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const initialState = {
  banner: [],
  capabilityBuilding: [],
  isLoading: false,
  error: null,
};

export const capabilityBuildingPrograms = createSlice({
  name: "capabilityBuildingPrograms",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataCapability.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataCapability.fulfilled, (state, action) => {
        state.isLoading = false;
        state.capabilityBuilding = action.payload;
      })
      .addCase(getDataCapability.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default capabilityBuildingPrograms.reducer;
