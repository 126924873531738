import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const Timeline = lazy(() => import("./components/timeline/Timeline"));
const Badges = lazy(() => import("./components/badges/Badges"));


const Awards = () => {


  return (
    <>
      <Banner />
      <Timeline />
      <Badges />
    </>
  );
};

export default Awards;
