import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./loading.module.scss";

export default function Loading() {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const element = (
    // <div className={styles.loading}>
    //   <div className={styles.loading_dot}></div>
    //   <div className={styles.loading_outline}>
    //     <span></span>
    //   </div>
    // </div>
    <div className={styles.loading}>Loading&#8230;</div>
  );

  return ReactDOM.createPortal(element, document.getElementById("popup"));
}
