import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./home/homeSlice"; 
import groupOverviewReducer from "./about/groupOverview/groupOverviewSlice"; 
import subsidiariesReducer from "./about/subsidiaries/subsidiariesSlice"; 
import socialImpactSliceReducer from "./about/socialImpact/socialImpactSlice"; 
import marketReducer from "./about/market/marketSlice"; 
import awardsReducer from "./about/awards/awardsSlice"; 
import historyReducer from "./about/history/historySlice"; 
import k12Reducer from "./solutions/k12/k12Slice"; 
import capabilityBuildingProgramsReducer from "./solutions/capabilityBuildingPrograms/capabilityBuildingProgramsSlice"; 
import softwareDevelopmentReducer from "./solutions/softwareDevelopment/softwareDevelopmentSlice"; 
import investingStartupsReducer from "./solutions/investingStartups/investingStartupsSlice"; 
import vocationalEducationReducer from "./solutions/vocationalEducation/vocationalEducationSlice"; 
import publishingSolutionsReducer from "./solutions/publishingSolutions/publishingSolutionsSlice"; 
import digitalLearningReducer from "./solutions/digitalLearning/DigitalLearningSlice"; 
import projectsReducer from "./projects/projectsSlice"; 
import printingAcademyReducer from "./educationalInstitutions/printingAcademy/printingAcademySlice"; 
import appliedTechnologySchoolReducer from "./educationalInstitutions/appliedTechnologySchool/appliedTechnologySchoolSlice"; 
import internationalAcademyReducer from "./educationalInstitutions/internationalAcademy/internationalAcademySlice"; 
import examCenterReducer from "./educationalCertificates/examCenter/examCenterSlice"; 
import addressesReducer from "./contactUs/contactUsSlice"; 
import newsReducer from "./news/newsSlice"; 
import teachingReducer from "./solutions/teaching/teachingSlice"; 
import corporatesReducer from "./solutions/corporates/corporatesSlice"; 
import higherEducationReducer from "./solutions/higherEducation/higherEducationSlice"; 
import websiteSearchSliceReducer from "./search/searchSlice"; 

export const store = configureStore({
  reducer: {
    home: homeReducer,
    groupOverview: groupOverviewReducer,
    socialImpact: socialImpactSliceReducer,
    subsidiaries: subsidiariesReducer,
    market: marketReducer,
    awards: awardsReducer,
    history: historyReducer,
    k12: k12Reducer,
    capabilityBuildingPrograms: capabilityBuildingProgramsReducer,
    software: softwareDevelopmentReducer,
    investingStartups: investingStartupsReducer,
    vocationalEducation: vocationalEducationReducer,
    publishingSolutions: publishingSolutionsReducer,
    digitalLearning: digitalLearningReducer,
    projects: projectsReducer,
    printingAcademy: printingAcademyReducer,
    appliedTechnologySchool: appliedTechnologySchoolReducer,
    internationalAcademy: internationalAcademyReducer,
    examCenter: examCenterReducer,
    addressesSection: addressesReducer,
    news: newsReducer,
    teach: teachingReducer,
    corporates: corporatesReducer,
    higherEducation: higherEducationReducer,
    search: websiteSearchSliceReducer
  }
});
