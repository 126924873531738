import * as yup from "yup";
import { t } from "i18next";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const contactUsSchema = yup.object().shape({
  firstName: yup.string().required().min(3, ),
  lastName: yup.string().required().min(3, ),
  email: yup.string().required(t("emailRequired")).email(t("emailInvalid")),
  phoneNumber: yup
    .string()
    .required(t("phoneNumberRequired"))
    .matches(/^[0-9]+$/, t("phoneNumberNumeric"))
    .min(10, t("phoneNumberMinLength"))
    .max(12, t("phoneNumberMaxLength")),
  comment: yup
    .string()
    .required()
    .test("default", t("comment"), (value) => {
      return value != 0;
    }),
  jobTitle: yup
    .string()
    .required()
    .test("len",  (val) => val.split(" ").length >= 1)
    .trim()
    .min(3),
  companyName: yup
    .string()
    .required()
    .test("len",  (val) => val.split(" ").length >= 1)
    .trim()
    .min(3),
  country: yup
    .string()
    .required()
    .test("len", (val) => val.split(" ").length >= 1)
    .trim()
    .min(3),
});
