import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const OurHistory = lazy(() => import("./components/ourHistory/OurHistory"));
const Timeline = lazy(() => import("./components/timeline/Timeline"));


const History = () => {
  return (
    <>
      <Banner />
      <OurHistory />
      <Timeline />
    </>
  );
};

export default History;
