import React, { useEffect } from "react";
import ServicesSlider from '../../../../../components/sliders/servicesSlider/ServicesSlider'
import { useDispatch, useSelector } from "react-redux";
import { getDataCategoriesServices } from "../../../../../app/solutions/vocationalEducation/vocationalEducationSlice";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import Error from "../../../../../components/utils/portal/error/Error";

function Slider() {

   const dispatch = useDispatch();
   const { isLoading, categoriesServices, error } = useSelector((state) => state.vocationalEducation);
   
   useEffect(() => {
     dispatch(getDataCategoriesServices());
   }, []);
   // change language handler
   const { t } = useTranslation();
   // loading
   if (isLoading) {
     return <Loading />;
   }
   // error
   if (error) {
     return <Error error={error.message} />;
   }

   
  return (
    <section className='slider pb-lg-0 pb-5'>
      <ServicesSlider data={categoriesServices?.result?.services} id={categoriesServices?.result?.services.length} />
    </section>
  )
}

export default Slider