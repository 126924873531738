import React from "react";
import style from "./HalfSection6.module.scss";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Img from "../utils/img/Img";
import { Fade } from "react-reveal";

function HalfSection6({ id, desc, img, link, linkTitle, className, logoSrc, logo }) {
  return (
    <div className={`${className} ${style.halfSection}`}>
      <Row>
        <Col lg={7} className="col1 order-lg-1 order-2">
          <div className={style.halfSection_content}>
            {logo && (
              <div className={`removeInText ${style.halfSection_imgContainer_logos}`}>
                {logoSrc.map((logo) => {
                  return <Img src={logo} alt="logo" className="img-fluid" />;
                })}
              </div>
            )}
            <Fade left duration={2500} delay={500}>
              <p className={style.halfSection_content_desc} dangerouslySetInnerHTML={{ __html: desc }} />
            </Fade>
            <Fade left duration={2500} delay={1000}>
              {link && (
                <Link to={`/${id}`} className={`d-inline-block ${style.halfSection_content_link}`}>
                  {linkTitle}
                </Link>
              )}
            </Fade>
          </div>
        </Col>
        <Col lg={5} className={`${style.halfSection_cust} col2 order-lg-2 order-1`}>
          <div className={style.halfSection_imgContainer}>
            {logo && (
              <div className={`addInText ${style.halfSection_imgContainer_logos}`}>
                {logoSrc.map((logo) => {
                  return <Img src={logo} alt="logo" className="img-fluid" />;
                })}
              </div>
            )}
            <Img src={img} alt="logo" className={style.halfSection_imgContainer_img} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HalfSection6;
