import { lazy, useEffect } from "react";
import { useLocation } from "react-router-dom";
const OurPartners = lazy(() => import("./components/ourPartners/OurPartners"));

const OurPurpose = lazy(() => import("./components/ourPurpose/OurPurpose"));
const Banner = lazy(() => import("./components/banner/Banner"));
const OurBrings = lazy(() => import("./components/ourBrings/OurBrings"));
// const WhoWeServe = lazy(() => import("./components/whoWeServe/WhoWeServe"));
const OurValues = lazy(() => import("./components/ourValues/OurValues"));
const Figures = lazy(() => import("./components/figures/Figures"));
const WhatWeDo = lazy(() => import("./components/whatWeDo/WhatWeDo"));


const GroupOverview = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    // Attach the handler to the window's load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash])

  return (
    <>
      <Banner />
      <OurPurpose />
      <WhatWeDo />
      {/* <WhoWeServe /> */}
      <OurBrings />
      <OurValues />
      <Figures />
      <OurPartners />
    </>
  );
};

export default GroupOverview;
