// useContactUs.js
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { contactUsSchema } from "../schema/contactUs";

export default function useContactUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      jobTitle: "",
      companyName: "",
      country: "",
      comment: "",
    },
    resolver: yupResolver(contactUsSchema),
  });

  const onSubmit = async (formData) => {
    try {
      const response = await fetch(
        "https://backend.nahdetmisr.com:10443/api/Contacts/CreateContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Form submitted successfully!");
        reset();
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit form. Please try again later.");
    }
  };

  return { register, errors, handleSubmit, onSubmit };
}
