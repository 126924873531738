import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t, i18n } from "i18next";
import { useTranslation } from "react-i18next";

import style from "./contactForm.module.scss";
import { Col, Row } from "react-bootstrap";
import MainButton from "../../../../components/utils/buttons/MainButton";
import useContactUs from "../../../../hooks/useContactUs";

const ContactForm = () => {
  const { register, errors, handleSubmit, onSubmit } = useContactUs();
  const { i18n, t } = useTranslation();

  return (
    <div className={style.contactForm}>
      <h2 className={style.contactForm_title}>{t("GetinTouch")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-4">
          <Col md={6} className="mb-md-0 mb-4 ">
            <div>
              <input
                className={style.contactForm_input}
                type="text"
                {...register("firstName")}
                placeholder={t("firstNamePlaceholder")}
              />
              {errors.firstName && (
                <p className="invalid mt-2">
                  {i18n.language === "en" ? "First Name is required" : "الاسم لاول مطلوب"}
                  {/* {errors.firstName.message} */}
                </p>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div>
              <input
                className={style.contactForm_input}
                type="text"
                {...register("lastName")}
                placeholder={t("lastNamePlaceholder")}
              />
              {errors.lastName && (
                <p className="invalid mt-2">
                  {i18n.language === "en" ? "Last Name is required" : "الاسم الاخير مطلوب"}

                  {/* {errors.lastName.message} */}
                </p>
              )}
            </div>
          </Col>
        </Row>

        <div className="mb-4">
          <input
            className={style.contactForm_input}
            type="email"
            {...register("email")}
            placeholder={t("emailPlaceholder")}
          />
          {errors.email && (
            <p className="invalid mt-2">
              {/* {errors.email.message} */}
              {i18n.language === "en" ? "Email is required" : "البريد الالكتروني مطلوب"}
            </p>
          )}
        </div>
        <div className="mb-4">
          <input
            className={style.contactForm_input}
            type="text"
            {...register("phoneNumber")}
            placeholder={t("phoneNumberPlaceholder")}
          />
          {errors.phoneNumber && (
            <p className="invalid mt-2">
              {/* {errors.phoneNumber.message} */}
              {i18n.language === "en" ? "Phone Number is required" : "رقم التليفون  مطلوب"}
            </p>
          )}
        </div>
        <div className="mb-4">
          <input
            className={style.contactForm_input}
            type="text"
            {...register("jobTitle")}
            placeholder={t("jobTitlePlaceholder")}
          />
          {errors.jobTitle && (
            <p className="invalid mt-2">
              {/* {errors.jobTitle.message} */}
              {i18n.language === "en" ? "Job Title is required" : "المسمي الوظيفي مطلوب"}
            </p>
          )}
        </div>
        <div className="mb-4">
          <input
            className={style.contactForm_input}
            type="text"
            {...register("companyName")}
            placeholder={t("companyNamePlaceholder")}
          />
          {errors.companyName && (
            <p className="invalid mt-2">
              {/* {errors.companyName.message} */}
              {i18n.language === "en" ? "Company Name is required" : "اسم الشركه  مطلوب"}
            </p>
          )}
        </div>
        <div className="mb-4">
          <input
            className={style.contactForm_input}
            type="text"
            {...register("country")}
            placeholder={t("countryPlaceholder")}
          />
          {errors.country && (
            <p className="invalid mt-2">
              {/* {errors.country.message} */}
              {i18n.language === "en" ? "Country is required" : "الدوله مطلوب"}
            </p>
          )}
        </div>
        <div className="mb-4">
          <textarea
            className={`${style.contactForm_textarea} ${style.contactForm_input}`}
            {...register("comment")}
            placeholder={t("commentPlaceholder")}
          ></textarea>
          {errors.comment && (
            <p className="invalid mt-2">
              {/* {errors.comment.message} */}
              {i18n.language === "en" ? "Comment is required" : "التعليق مطلوب"}
            </p>
          )}
        </div>
        <MainButton type="submit">{t("submit")}</MainButton>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
