import { lazy } from "react";
import Curricula from "./curricula/Curricula";
import Rehlatie from "./rehlatie/Rehlatie";
import Childhood from "./childhood/Childhood";
const ContentDevelopment = lazy(() => import("./contentDevelopment/ContentDevelopment"));
const Qessatie = lazy(() => import("./Qessatie/Qessatie"));
const Slider = lazy(() => import("./slider/Slider"));
const Contact = lazy(() => import("./contact/Contact"));



const Products = () => {

  
  return (
    <> 
      <Slider />
      <ContentDevelopment/>
      <Qessatie/>
      <Rehlatie />
      <Curricula />
      <Childhood />
      <Contact />
    </>
  );
};

export default Products;
