import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import HalfSection2 from "../../../../../components/halfSection2/HalfSection2";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataVocational } from "../../../../../app/solutions/vocationalEducation/vocationalEducationSlice";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Img from "../../../../../components/utils/img/Img";

function Startup() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, vocational, error } = useSelector((state) => state.vocationalEducation);
  useEffect(() => {
    dispatch(getDataVocational());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="Startup  pb-5">
      <Container>
        <HalfSection2
          img={vocational?.services?.image}
          // title={`${i18n.language === "en" ? vocational?.services?.titleEn : vocational?.services?.titleAr}`}
          desc={`${i18n.language === "en" ? vocational?.services?.titleEn : vocational?.services?.titleAr}`}
          className="whtBg mb-3"
        />

        {vocational?.services?.serviceCategoryServicesContents?.map((startup) => {
          return startup?.serviceContentListDetails?.map((section, index) => (
            <Row className="w-90" key={index}>
              {section?.serviceCategoryServiceContentLists?.map((box) => (
                <Col lg={3} md={6} className="mb-4" key={box.id}>
                  <Fade left duration={2500} delay={`${index}50`}>
                    <div className="logoBox mainBgColor p-3">
                      <Img src={box?.image} alt={box?.titleEn} className="img-fluid mb-2 icon" />
                      <h5>{`${i18n.language === "en" ? box?.titleEn : box?.titleAr}`}</h5>
                    </div>
                  </Fade>
                </Col>
              ))}
            </Row>
          ));
        })}
        {vocational?.services?.serviceCategoryServicesContents?.map((desc) => {
          return <p dangerouslySetInnerHTML={{__html: i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr}} />

          

          ;
        })}
      </Container>
    </section>
  );
}

export default Startup;
