import React from "react";
import style from "./searchCard.module.scss";
import { Link } from "react-router-dom";

function SearchCard({ desc, link, title, linkText }) {



  return (
    <div className={style.searchCard}>
      <div>
        <h4 className={style.searchCard_title}>{title}</h4>
        <p className={style.searchCard_desc} dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
      <a href={link} className={style.searchCard_link}>
        {linkText}
      </a>
    </div>
  );
}

export default SearchCard;
