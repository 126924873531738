import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("services/softwareDevelopment/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/SoftwareDevelopment/SoftwareDevelopmentHeadSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataDesc = createAsyncThunk("services/softwareDevelopmentSlice/getDataDesc", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/SoftwareDevelopment/SoftwareDevelopmentContentSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  banner: [],
  desc: [],
  isLoading: false,
  error: null,
};

export const softwareDevelopmentSlice = createSlice({
  name: "software",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataDesc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataDesc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.desc = action.payload;
      })
      .addCase(getDataDesc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default softwareDevelopmentSlice.reducer;
