import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataAddresses = createAsyncThunk("addresses/getDataAddresses", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/Contacts/ContactAreas");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const postContactForm = createAsyncThunk("addresses/postContactForm", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.post("https://backend.nahdetmisr.com:10443/api/Contacts/ContactAreas");
    return response.data;
    
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  addresses: [],
  contactForm: [],
  isLoading: false,
  error: null,
};

export const addressesSlice = createSlice({
  name: "addressesSection",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataAddresses.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataAddresses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addresses = action.payload;
      })
      .addCase(getDataAddresses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postContactForm.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postContactForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contactForm = action.payload;
      })
      .addCase(postContactForm.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default addressesSlice.reducer;
