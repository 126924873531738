import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("projects/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/ProjectsPage/ProjectPageHeadSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getDataProjectsDetails = createAsyncThunk("projects/getDataProjectsDetails", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/ProjectsPage/ProjectPageSecondSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurProjects = createAsyncThunk("projects/getDataOurProjects", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/ProjectsPage/GetAll");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  banner: [],
  projectsDetails: [],
  ourProjects: [],
  isLoading: false,
  error: null,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataProjectsDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataProjectsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectsDetails = action.payload;
      })
      .addCase(getDataProjectsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataOurProjects.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourProjects = action.payload;
      })
      .addCase(getDataOurProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default projectsSlice.reducer;
