import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataWebsiteSearch = createAsyncThunk("website/getDataWebsiteSearch", async (searchKey, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get(
      `https://backend.nahdetmisr.com:10443/api/home/search?searchKey=${searchKey}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  
  websiteSearch: [],
  isLoading: false,
  error: null,
};

const websiteSearchSlice = createSlice({
  name: "website",
  initialState,
  extraReducers: (builder) => {
    builder
     
      .addCase(getDataWebsiteSearch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataWebsiteSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.
        websiteSearch = action.payload;
      })
      .addCase(getDataWebsiteSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default websiteSearchSlice.reducer;