import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const Teach = lazy(() => import("./components/teach/Teach"));
const Contact = lazy(() => import("./components/contact/Contact"));


const Teaching = () => {


  
  return (
    <> 
      <Banner />
      <Teach />
      <Contact />
    </>
  );
};

export default Teaching;
