import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import HalfSection5 from "../../../../components/halfSection5/HalfSection5";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Loading from "../../../../components/utils/portal/loading/Loading";
import Error from "../../../../components/utils/portal/error/Error";
import { useDispatch, useSelector } from "react-redux";
import MainBanner from "../../../../components/mainBanner/MainBanner";

function Projects() {
  const param = useParams();



  const getDataProjects = createAsyncThunk("socialImpact/getDataProjects", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `https://backend.nahdetmisr.com:10443/api/Sustainability/GetSunProjectDetails?id=${param.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, projects, error } = useSelector((state) => state.socialImpact);
  useEffect(() => {
    dispatch(getDataProjects());
  }, []);

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <>
      <section className="banner">
        <MainBanner
          background={projects?.result?.image}
          title={`${i18n.language === "en" ? projects?.result?.titleEn : projects?.result?.titleAr}`}
          // subTitle={`${i18n.language === "en" ? projects?.result?.descriptionEn : projects?.result?.descriptionAr}`}
        />
      </section>
      <section>
        <Container>
          <HalfSection5
            key={projects?.result?.id}
            img={projects?.result?.mainImage}
            desc={`${i18n.language === "en" ? projects?.result?.descriptionEn : projects?.result?.descriptionAr}`}
            className="whtBg"
            logo={true}
            logoSrc={projects?.result?.logo}
          />
        </Container>
      </section>
    </>
  );
}

export default Projects;
