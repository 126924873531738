import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("home/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/headSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataAbout = createAsyncThunk("home/getDataAbout", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/aboutSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getDataWhatWeDo = createAsyncThunk("home/getDataWhatWeDo", async (_, thunkAPI) => {
   const { rejectWithValue } = thunkAPI;
   try {
     const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/whatwedo");
     return response.data;
   } catch (error) {
     return rejectWithValue(error.message);
   }
 });
export const getDataServe = createAsyncThunk("home/getDataServe", async (_, thunkAPI) => {
   const { rejectWithValue } = thunkAPI;
   try {
     const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/whoweserve");
     return response.data;
   } catch (error) {
     return rejectWithValue(error.message);
   }
 });
 export const getDataProject = createAsyncThunk("home/getDataProject", async (_, thunkAPI) => {
   const { rejectWithValue } = thunkAPI;
   try {
     const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/latestprojects");
     return response.data;
   } catch (error) {
     return rejectWithValue(error.message);
   }
 });
 export const getDataAdditions = createAsyncThunk("home/getDataAdditions", async (_, thunkAPI) => {
   const { rejectWithValue } = thunkAPI;
   try {
     const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/latestadditions");
     return response.data;
   } catch (error) {
     return rejectWithValue(error.message);
   }
 });
 export const getDataNews = createAsyncThunk("home/getDataNews", async (_, thunkAPI) => {
   const { rejectWithValue } = thunkAPI;
   try {
     const response = await axios.get("https://backend.nahdetmisr.com:10443/api/home/latestnews");
     return response.data;
   } catch (error) {
     return rejectWithValue(error.message);
   }
 });

 export const getDataPartners = createAsyncThunk("home/getDataOurPartners", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get("https://backend.nahdetmisr.com:10443/api/About/GetOverViewPartners");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
const initialState = {
  banner: [],
  about: [],
  whatWeDo: [],
  serve: [],
  projects: [],
  additions: [],
  news: [],
  partners: [],
  isLoading: false,
  error: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })


      .addCase(getDataAbout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataAbout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.about = action.payload;
      })
      .addCase(getDataAbout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataWhatWeDo.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
      .addCase(getDataWhatWeDo.fulfilled, (state, action) => {
         state.isLoading = false;
         state.whatWeDo = action.payload;
       })
       .addCase(getDataWhatWeDo.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       })

      .addCase(getDataServe.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
       .addCase(getDataServe.fulfilled, (state, action) => {
         state.isLoading = false;
         state.serve = action.payload;
       })
       .addCase(getDataServe.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       })
       .addCase(getDataProject.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
       .addCase(getDataProject.fulfilled, (state, action) => {
         state.isLoading = false;
         state.projects = action.payload;
       })
       .addCase(getDataProject.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       })
       .addCase(getDataAdditions.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
       .addCase(getDataAdditions.fulfilled, (state, action) => {
         state.isLoading = false;
         state.additions = action.payload;
       })
       .addCase(getDataAdditions.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       })
       .addCase(getDataNews.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
       .addCase(getDataNews.fulfilled, (state, action) => {
         state.isLoading = false;
         state.news = action.payload;
       })
       .addCase(getDataNews.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       })
       .addCase(getDataPartners.pending, (state) => {
         state.isLoading = true;
         state.error = null;
       })
       .addCase(getDataPartners.fulfilled, (state, action) => {
         state.isLoading = false;
         state.partners = action.payload;
       })
       .addCase(getDataPartners.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       });
  },
});

export default homeSlice.reducer;
