import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const DigitalLearningContent = lazy(() => import("./components/digitalLearningContent/DigitalLearningContent"));
const DigitalLearningTeams = lazy(() => import("./components/digitalLearningTeams/DigitalLearningTeams"));
const Slider = lazy(() => import("./components/slider/Slider"));
const Contact = lazy(() => import("./components/contact/Contact"));
const Robust = lazy(() => import("./components/robust/Robust"));
const Services = lazy(() => import("./components/services/Services"));
const Learning = lazy(() => import("./components/learning/Learning"));
const Digitization = lazy(() => import("./components/digitization/Digitization"));
const Strategy = lazy(() => import("./components/strategy/Strategy"));


const DigitalLearning = () => {

  return (
    <> 
      <Banner />
      <DigitalLearningContent />
      <DigitalLearningTeams />
      <Robust />
      <Slider />
      <Services />
      <Digitization />
      <Learning />
      <Strategy />
      <Contact />
    </>
  );
};

export default DigitalLearning;
