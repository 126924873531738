import Contact from "./contact/Contact";
import Offer from "./offer/Offer";

function Services() {
    return <>
        <Offer />
        <Contact bg={true} />
    </>
}

export default Services;
