import { lazy } from "react";
import Digitization from "./digitization/Digitization";
const ContentDevelopment = lazy(() => import("./contentDevelopment/ContentDevelopment"));
const WhatWeDo = lazy(() => import("./whatWeDo/WhatWeDo"));
const CurriculaDevelopment = lazy(() => import("./curriculaDevelopment/CurriculaDevelopment"));
const Slider = lazy(() => import("./slider/Slider"));
const Contact = lazy(() => import("./contact/Contact"));



const Services = () => {

  
  return (
    <> 
      <Slider />
      <CurriculaDevelopment/>
      <ContentDevelopment/>
      <Digitization />
      <WhatWeDo/>
      <Contact />
    </>
  );
};

export default Services;
