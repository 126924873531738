import React from "react";
import style from "./notFound.module.scss";

const NotFound = ({ title, desc }) => {
  return (
    <div className={style.notFound}>
      <h2 className={style.notFound_title}>{title}</h2>
      <p className={style.notFound_desc}>{desc}</p>
    </div>
  );
};

export default NotFound;
