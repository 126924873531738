import { lazy } from "react";
const OurNews = lazy(() => import("./components/ourNews/OurNews"));

const News = () => {
  return (
    <>
      <OurNews />
    </>
  );
};

export default News;
