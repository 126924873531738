import React from "react";
import style from "./HalfSection2.module.scss";
import { Col, Row } from "react-bootstrap";
import Img from "../utils/img/Img";
import Title from "../title/Title";

function HalfSection2({ title, desc, img, className, logo, logoSrc }) {
  return (
    <div className={`${className} ${style.halfSection2}`}>
      {title && <Title title={title} className="mb-2" />}

      <Row>
        <Col lg={8} className="order-lg-first order-last">
          <div className={style.halfSection2_content}>
            <p className={style.halfSection_content_desc} dangerouslySetInnerHTML={{ __html: desc }} />
          </div>
        </Col>
        <Col lg={4} className="order-lg-last mb-lg-0 mb-4">
          {logo && (
            <div className="text-center mb-4">
              <Img src={logoSrc} alt={title} className="img-fluid" />
            </div>
          )}
          <Img src={img} alt={title} className="img-fluid w-100 object-fit-cover" />
        </Col>
      </Row>
    </div>
  );
}

export default HalfSection2;
