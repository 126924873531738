import React, { useRef, useState } from "react";
import style from "./servicesSlider.module.scss";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Navigation } from "swiper/modules";
import Img from "../../utils/img/Img";
import { Link } from "react-router-dom";

const ServicesSlider = ({ title, data, id }) => {
  const ref = useRef();
  const [hoveredSlide, setHoveredSlide] = useState(1);
  const { i18n, t } = useTranslation();

  return (
    <>
      {title && <Container>
        <h3 className={style.title}>{t(`${title}`)}</h3>
      </Container>}
      <div className={`servicesSlider ${style.servicesSlider}`}>
        <div className={style.servicesSlider_overlay}>
          {data?.map((slide) => (
            <Img
              id="img"
              key={slide.id}
              className={`${hoveredSlide === slide.id ? "d-block" : ""} ${style.servicesSlider_overlay_img}`}
              src={slide?.image}
              alt="logo"
            />
          ))}
        </div>
        <Swiper
          className={style.servicesSlider_slider}
          spaceBetween={3}
          slidesPerView={id <= 4 ? id : 4}
          modules={[Navigation]}
          loop={true}
          speed={2000}
          autoplay={{
            delay: 2000,
          }}
          navigation={true}
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: id <= 4 ? id : 4,
            },
          }}
        >
          {data?.map((slide) =>
            slide?.serviceCategoryServicesContents.map((box) => (
              <SwiperSlide
                onMouseEnter={() => setHoveredSlide(slide.id)}
                key={box.id}
                className={`${style.servicesSlider_slide} ${
                  i18n.language === "en" ? style.servicesSlider_slideEn : style.servicesSlider_slideAr
                }`}
              >
                <a href={`#${slide?.titleEn?.toLowerCase().replace(/& /g, "").replace(/ /g, "-").trim()}`}>
                  <div className={style.servicesSlider_slide_content}>
                    <h4 className={style.servicesSlider_slide_content_title}>
                      {i18n.language === "en" ? slide.titleEn : slide.titleAr}
                    </h4>
                  </div>
                </a>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </>
  );
};

export default ServicesSlider;
