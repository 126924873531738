import React from "react";
import ContactUsSection2 from "../../../../../components/contactUs2/ContactUs2";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

function Contact2() {
  const { t } = useTranslation();

  return (
        <section className="contctUs pb-5">
        <ContactUsSection2 className="d-lg-flex text-lg-start text-center" linkText={t("Website")} ios="https://apps.apple.com/eg/app/nahdet-misr/id1605480294" android="https://play.google.com/store/apps/details?id=store_app_interface.nahdet_misr&hl=ar&gl=US&pli=1"
          link={"https://www.nahdetmisrbookstore.com/"} contact2={true} desc={t("DownloadEcommerceAppNowOrVisitOur")} download={true} />
      </section>
  );
}

export default Contact2;
