import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataCategoriesServices } from "../../../../../app/solutions/vocationalEducation/vocationalEducationSlice";
import { useTranslation } from "react-i18next";
import HalfSection from "../../../../../components/halfSection/HalfSection";

function Digitization() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, categoriesServices, error } = useSelector((state) => state.vocationalEducation);
  useEffect(() => {
    dispatch(getDataCategoriesServices());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <section className="Digitization pt-4">
      <Container>
        {categoriesServices?.result?.services.slice(6, 7)?.map((offer) => {
          return offer?.serviceCategoryServicesContents?.map((section) => {
            return (
              <div className="evenOddSection2">
                <HalfSection
                  id={section.id}
                  title={`${i18n.language === "en" ? section.titleEn : section.titleAr}`}
                  desc={`${i18n.language === "en" ? section.descriptionEn : section.descriptionAr}`}
                  img={section.image}
                  className={"p-0 mb-lg-0 mb-4"}
                  col1Lg={7}
                  col2Lg={5}
                  transparent={true}
                  href={section.titleEn}
                />
              </div>
            );
          });
        })}
      </Container>
    </section>
  );
}

export default Digitization;
