import React, { useEffect } from "react";
import Banner from "./components/banner/Banner";
import Offer from "./components/offer/Offer";
import Contact from "./components/contact/Contact";
import Startup from "./components/startup/Startup";
import Slider from "./components/slider/Slider";
import Up from "../../../components/utils/up/Up";
import Technical from "./components/Technical/Technical";
import CurriculaDevelopment from "./components/curriculaDevelopment/CurriculaDevelopment";
import Capability from "./components/capability/Capability";
import Digitization from "./components/digitization/Digitization";
import { useLocation } from "react-router-dom";

const VocationalEducation = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    // Attach the handler to the window's load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash])
  return (
    <> 
      <Banner />
      <Startup />
      <Slider />
      <Offer />
      <Technical />
      <CurriculaDevelopment />
      <Capability />
      <Digitization />
      <Contact />
      <Up />
      
    </>
  );
};

export default VocationalEducation;
