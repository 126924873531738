import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Container, Row } from "react-bootstrap";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataAllCertificates } from "../../../../../app/educationalCertificates/examCenter/examCenterSlice";
import { useTranslation } from "react-i18next";
import CertificatesBox from "../../../../../components/boxes/certificatesBox/CertificatesBox";

function Certificates() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, certificates, error } = useSelector((state) => state.examCenter);
  useEffect(() => {
    dispatch(getDataAllCertificates());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="CertificateSecondSection pb-5" id="training-programs-for-k-12-educators">
      <Container>

        <div className="printingAcademy_services">
          <Row className="justify-content-center">
            {certificates?.result?.map((certificate) => {
              return (
                <Col lg={4} md={6} key={certificate.id} className="mb-4">
                     <CertificatesBox img={certificate?.image} title={`${i18n.language === "en" ? certificate?.titleEn : certificate?.titleAr}`} desc={`${i18n.language === "en" ? certificate?.descriptionEn : certificate?.descriptionAr}`} />
               
                </Col>
              );
            })}
          </Row>
          {/* <p dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? secondSection?.result?.descriptionEn : secondSection?.result?.descriptionAr}} /> */}
        </div>
      </Container>
    </section>
  );
}

export default Certificates;
