import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("teaching/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get(
      "https://backend.nahdetmisr.com:10443/api/OfferingTeaching/OfferingHeadSection"
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataTeach = createAsyncThunk(
  "teaching/getDataTeach",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/OfferingTeaching/GetOfferingCategoriesServices"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  banner: [],
  teach: [],
  isLoading: false,
  error: null,
};

export const teaching = createSlice({
  name: "teaching",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataTeach.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataTeach.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teach = action.payload;
      })
      .addCase(getDataTeach.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default teaching.reducer;
