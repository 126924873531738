import { lazy } from "react";
import PositiveImpact from "./components/positiveImpact/PositiveImpact";
import Steps from "./components/steps/Steps";
import Projects from "./components/projects/Porjects";
const Banner = lazy(() => import("./components/banner/Banner"));

const SocialImpact = () => {
  return (
    <>
      <Banner />
      <PositiveImpact />
      <Steps />
      <Projects />
    </>
  );
};

export default SocialImpact;
