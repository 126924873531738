import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import style from './up.module.scss'

function Up() {

   const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Add smooth scrolling behavior
      });
    };
  return (
    <div className={style.up} onClick={scrollToTop}>
      <FontAwesomeIcon icon={faChevronUp} />
    </div>
  )
}

export default Up