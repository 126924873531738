import React from "react";
import style from "./HalfSection.module.scss";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Img from "../utils/img/Img";
import { Fade } from "react-reveal";

function HalfSection({
  id,
  noPadding,
  title,
  desc,
  img,
  link,
  link2,
  path,
  linkTitle,
  className,
  col1Lg,
  col2Lg,
  logo,
  logoSrc,
  internal,
  ps,
  transparent,
  href,
  ptb0,
  psOdd
}) {
  return (
    <div id={href && href.toLowerCase().replace(/& /g, "").replace(/ /g, "-").trim()} className={`${className} ${style.halfSection} ${transparent && "bgTransparent"}`}>
      <Row className={internal && "justify-content-between"}>
        <Col lg={col1Lg} className="col1 p-lg-0 order-lg-first order-last d-flex align-items-center">
          <div className={`${style.halfSection_content} ${ptb0 && "pt-lg-0 pb-0"}  ${noPadding ? "ps-3 pe-3" : ""} ${ps && "psCust"} ${psOdd && "psOdd"}`}>
            {title && (
              <Fade left duration={1000}>
                <h3 className={style.halfSection_content_title}>{title}</h3>
              </Fade>
            )}
            <Fade left duration={1000} delay={500}>
              <p className={style.halfSection_content_desc} dangerouslySetInnerHTML={{ __html: desc }} />
            </Fade>
            {link && (
              <Fade left duration={1000} delay={600}>
                <Link to={`/${id}`} className={`d-inline-block ${style.halfSection_content_link}`}>
                  {linkTitle}
                </Link>
              </Fade>
            )}
            {link2 && (
              <Fade left duration={1000} delay={700}>
                <Link to={`/${path}`} className={`d-inline-block ${style.halfSection_content_link}`}>
                  {linkTitle}
                </Link>
              </Fade>
            )}
          </div>
        </Col>
        <Col lg={col2Lg} className="col2 p-lg-0 order-lg-last">
          {logo && (
            <div className=" d-flex gap-5 justify-content-center mb-4">
              {logoSrc.length > 1 ? (
                logoSrc.map((img, index) => {
                  return <Img src={img} alt={img} key={index} className="img-fluid  smallWidth" />;
                })
              ) : (
                <Img src={logoSrc} alt={logoSrc} className="img-fluid  smallWidth" />
              )}
            </div>
          )}
          <div className="zoomIn">
            <Img src={img} alt={title} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HalfSection;
