import { lazy } from "react";
import Certificates from "./components/certificates/Certificates";
const Banner = lazy(() => import("./components/banner/Banner"));
const ExamCenterContent = lazy(() => import("./components/examCenterContent/ExamCenterContent"));
const Contact = lazy(() => import("./components/contact/Contact"));


const ExamCenter = () => {


  return (
    <> 
      <Banner />
      <ExamCenterContent />
      <Certificates />
      <Contact />
    </>
  );
};

export default ExamCenter;
