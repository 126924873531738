import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const PrintingAcademyContent = lazy(() => import("./components/printingAcademyContent/PrintingAcademyContent"));
const Contact = lazy(() => import("./components/contact/Contact"));


const PrintingAcademy = () => {


  return (
    <> 
      <Banner />
      <PrintingAcademyContent />
      <Contact />
    </>
  );
};

export default PrintingAcademy;
