import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import Error from "../../components/utils/portal/error/Error";
import Loading from "../../components/utils/portal/loading/Loading";
import { getDataWebsiteSearch } from "../../app/search/searchSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SearchCard from "../../components/cards/searchCard/SearchCard";

function Search() {
  const { searchKey } = useParams();

  const dispatch = useDispatch();
  const { isLoading, websiteSearch, error } = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(getDataWebsiteSearch(searchKey));
  
  }, [dispatch, searchKey]);

  const { i18n, t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error.message} />;
  }


  return (
    <section className="search">
      <Container>
        <Row>
          {websiteSearch.map((search) => (
            <Col md={4} className="mb-4" key={Math.random()}>
              <SearchCard
                title={
                  i18n.language === "en"
                    ? `${search.titleEn.split(" ").slice(0, 10).join(" ")}...`
                    : `${search.titleAr.split(" ").slice(0, 10).join(" ")}...`
                }
                desc={
                  i18n.language === "en"
                    ? `${search.descrpitonEn.split(" ").slice(0, 10).join(" ")}...`
                    : `${search.descrpitonAr.split(" ").slice(0, 10).join(" ")}...`
                }
                linkText={t("readMore")}
                link={(() => {
                  switch (search.flag) {
                    case "additions":
                      return "/home#additions";
                    case "news":
                      return `${search.id === null ? `/news` : `/news/news-details/${search.id}`}`;
                    case "awards":
                      return "/about/awards";
                    case "home":
                      return "/home";
                    case "Publishing":
                      return "/solutions/publishing";
                    case "K2":
                      return "/solutions/k-12";
                    case "EducationalInstitutionTahea":
                      return "/educational-institutions/international-academy";
                    case "EducationalInstitutionTechnologySchool":
                      return "/educational-institutions/applied-technology-school";
                    case "EducationalInstitutionPrinting":
                      return "/educational-institutions/printing-academy";
                    case "History":
                      return "/about/history";
                    case "Socialimpact":
                      return `${search.id === null ? `/about/social-impact/` : `/about/social-impact/projects/${search.id}`}`;
                    case "Subsidiaries":
                      return "/about/subsidiaries";
                    case "marketing":
                      return "/about/markets";
                    case "projects":
                      return `${search.id === null ? `/projects/` : `/projects/project-details/${search.id}`}`;
                    case "Certificates":
                      return "/educational-certificates/exam-center";
                    case "VocationalEducation":
                      return "/solutions/vocational-education";
                    case "Corporates":
                      return "/solutions/corporates";
                    case "Teaching Arabic as a Foreign Language":
                      return "/solutions/teaching";
                    case "Higher Education":
                      return "/solutions/higher-education";
                    case "Investing in Startups":
                      return "/solutions/investing-startups";
                    default:
                      return "/";
                  }
                })()}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Search;
