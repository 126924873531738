import { lazy } from "react";
const ProjectDetails = lazy(() => import("./components/projectDetails/ProjectDetails"));
const OurProjects = lazy(() => import("./components/ourProjects/OurProjects"));
const Banner = lazy(() => import("./components/banner/Banner"));

const ProjectsPage = () => {
  return (
    <>
      <Banner />
      <ProjectDetails />
      <OurProjects />
    </>
  );
};

export default ProjectsPage;
