import React from "react";
import style from "./lineBox.module.scss";
import Img from "../../utils/img/Img";
import { Fade } from "react-reveal";

function LineBox({ title, desc, pattern, className, blacktitle, img, p0 }) {
  return (
    <div className={`${className} ${style.lineBox} ${p0 && "p-4"}`}>
      <div className={style.lineBox_content} >
        {img && <Img src={img} className={style.lineBox_img} alt={title} />}
        {title && <Fade up duration={2000}>
          <h4 className={`${blacktitle && style.lineBox_content_title2} ${style.lineBox_content_title}`}>{title}</h4>
        </Fade>}
        
        {desc !== null && <Fade up duration={2000} delay={500}>
          <p className={`${style.lineBox_content_desc}`}  dangerouslySetInnerHTML={{__html: desc}} />
        </Fade>}
      </div>
      <Img className={`img-fluid ${style.lineBox_pattern}`} src={pattern} alt={`${title} Pattern`} />
    </div>
  );
}

export default LineBox;
