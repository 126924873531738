import React from 'react'
import NotFound from '../../components/notFound/NotFound'
import { useTranslation } from 'react-i18next';

function NotFoundPage() {
  const { i18n } = useTranslation();

  return (
    <NotFound title="404" desc={i18n.language === "en" ? "Not Found" : "لم يتم العثور عليه"} />
  )
}

export default NotFoundPage