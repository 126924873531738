import React from "react";
import style from "./certificatesBox.module.scss";
import Img from "../../utils/img/Img";

function CertificatesBox({ img, title, desc }) {
  return (
    <div className={style.certificatesBox}>
      <Img className={style.certificatesBox_img} src={img} alt={title} />
      <h3 className={style.certificatesBox_title}>{title}</h3>
      <p className={style.certificatesBox_desc} dangerouslySetInnerHTML={{__html: desc}} />
    </div>
  );
}

export default CertificatesBox;
