import React from "react";
import { Link } from "react-router-dom";
import style from "./MainLink.module.scss";

function MainLink({ children, className, ...rest }) {
  return (
    <Link {...rest} className={`${style.mainLink} ${className}`}>
      {children}
    </Link>
  );
}

export default MainLink;
