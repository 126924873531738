import Contact from "./contact/Contact";
import ProductSection from "./productSection/ProductSection";


function Products() {
  return <>
    <ProductSection />
    <Contact />
  </>
}

export default Products;
