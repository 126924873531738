import React from "react";
import { Link } from "react-router-dom";
import style from "./contactButton.module.scss";

function ContactButton({ children, className, ...rest }) {
  return (
    <Link {...rest} className={`${style.contactButton} ${className}`}>
      {children}
    </Link>
  );
}

export default ContactButton;
