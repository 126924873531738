import Shimmering from "../../../hooks/Shimmering";


export default function Img({ src, alt, width, height, ...rest }) {
  const { shimmer, toBase64 } = Shimmering();

  return (
    <img
      src={src}
      alt={alt}
      loading="lazy"
      bl={`data:image/svg+xml;base64,${toBase64(
        shimmer(width, height)
      )}`}
      {...rest}
    />
  );
}
