import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const Map = lazy(() => import("./components/map/Map"));

const Markets = () => {
  return (
    <>
      <Banner />
      <Map />
    </>
  );
};

export default Markets;
