import React from "react";
import style from "./secondCard.module.scss";
import MainLink from "../../utils/links/MainLink";
import Img from "../../utils/img/Img";

function SecondCard({ img, desc, link, title, linkText }) {
  return (
    <div className={style.secondCard}>
      <div className="zoomIn h-auto">
        <Img src={`${img}`} alt="img" className={`${style.secondCard_img} img-fluid`} />
      </div>
      <div className={style.secondCard_content}>
        <div className="">
          <h4 className={style.secondCard_content_title}>{title}</h4>
          <p className={style.secondCard_content_desc} dangerouslySetInnerHTML={{ __html: desc }} />
        </div>
        <MainLink to={link} >{linkText}</MainLink>
      </div>
    </div>
  );
}

export default SecondCard;
