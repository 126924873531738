import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const TrainingPrograms = lazy(() => import("./components/trainingPrograms/TrainingPrograms"));
const Workforce = lazy(() => import("./components/workforce/Workforce"));
const VocationalEducators = lazy(() => import("./components/vocationalEducators/VocationalEducators"));
const Slider = lazy(() => import("./components/slider/Slider"));
const Contact = lazy(() => import("./components/contact/Contact"));


const CapabilityBuildingPrograms = () => {


  return (
    <> 
      <Banner />
      <Slider />
      <TrainingPrograms />
      <VocationalEducators />
      <Workforce />
      <Contact />
    </>
  );
};

export default CapabilityBuildingPrograms;
