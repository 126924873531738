import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const OurSubsidiaries = lazy(() => import("./components/ourSubsidiaries/OurSubsidiaries"));

const Subsidiaries = () => {
  return (
    <>
      <Banner />
      <OurSubsidiaries />
    </>
  );
};

export default Subsidiaries;
