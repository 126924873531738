import React from "react";
import style from "./internalSection.module.scss";
import Img from "../utils/img/Img";
import { Fade } from "react-reveal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function InternalSection({ title, desc, img, className, smallTitle, details, cards, data, id, details1, pt }) {
  // change language handler
  const { i18n } = useTranslation();

  return (
    <div className={`${className} ${style.internalSection}`} id={id}>
      <div className={`${pt && "pt-0"} ${style.internalSection_content}`}>
        {title && (
          <Fade up duration={2000}>
            <h3 className={style.internalSection_content_title}>{title}</h3>
          </Fade>
        )}
        {details ?
          <Fade up duration={2000} delay={500}>
            <div className={style.internalSection_content_img}>
              <div className={style.internalSection_content_img_details}>
                <Row className={`ps-4 ${style.internalSection_content_img_details_desc}`}>
                  {details1?.map((desc) => { return (<Col md={4}> <p> {i18n.language === "en" ? desc?.titleEn : desc?.titleAr} </p> </Col>); })}

                </Row>
              </div>

              <Img src={img} alt={title} />
            </div>
          </Fade>
          : <Fade up duration={2000} delay={500}>
            <div className={style.internalSection_content_img}>
              <Img src={img} alt={title} />
            </div>
          </Fade>}

        <Fade up duration={2000} delay={750}>
          <p className={style.internalSection_content_desc} dangerouslySetInnerHTML={{ __html: desc }} />
        </Fade>
      </div>
      {smallTitle && (
        <div className={style.internalSection_services}>
          <Fade up duration={2000} delay={1000}>
            <h3 className={style.internalSection_services_title}>{smallTitle}</h3>
          </Fade>
        </div>
      )}
      {cards && (
        <div className="programs">
          <Row>
            {data?.map((card, index) => (
              <Col lg={3} md={6} key={card.id} className="mb-lg-0 mb-4">
                <Fade left duration={2500} delay={`${index}50`}>
                  <div className="logoBox mainBgColor p-3">
                    <Img src={card?.image} alt={card?.titleEn} className="img-fluid mb-2 icon" />
                    <h5>{`${i18n.language === "en" ? card?.titleEn : card?.titleAr}`}</h5>
                  </div>
                </Fade>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default InternalSection;
