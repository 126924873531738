import React from "react";
import style from "./title.module.scss";
import { Fade } from "react-reveal";

function Title({ smallTitle, title, className }) {
  return (
    <div className={`${className} ${style.mainTitles}`}>
      {smallTitle && (
        <Fade up duration={1000}>
          <h6 className={style.mainTitles_smallTitle}>{smallTitle}</h6>
        </Fade>
      )}
      {title && <Fade up duration={2000} delay={500}><h2 className={style.mainTitles_title}>{title}</h2></Fade>}
    </div>
  );
}

export default Title;
