import React, { useEffect } from "react";
import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Slider from "./components/slider/Slider";
import Offer from "./components/offer/Offer";
import Up from "../../../components/utils/up/Up";
import { useLocation } from "react-router-dom";

const Corporates = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    // Attach the handler to the window's load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash])
  return (
    <> 
      <Banner />
      <Slider />
      <Offer />
      <Contact />
      <Up />
    </>
  );
};

export default Corporates;
