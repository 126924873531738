import React from "react";
import style from "./MainButton.module.scss";

function MainButton({ children, className, ...rest }) {
  return (
    <button {...rest} className={`${style.MainButton} ${className}`}>
      {children}
    </button>
  );
}

export default MainButton;
