import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("services/digitalLearning/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get(
      "https://backend.nahdetmisr.com:10443/api/DigitalLearning/DigitalLearningHeadSection"
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataDigitalContent = createAsyncThunk(
  "services/digitalLearning/getDataDigitalContent",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/DigitalLearning/DigitalLearningContentSection"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDataDigitalLearningTeams = createAsyncThunk(
  "services/digitalLearning/DigitalLearningTeams",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/DigitalLearning/DigitalLearningTeams"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDataDigitalLearningTeamContent = createAsyncThunk(
  "services/digitalLearning/digitalLearningTeamContent",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/DigitalLearning/DigitalLearningTeamContentSection"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDataDigitalLearningRobustContent = createAsyncThunk(
  "services/digitalLearning/digitalLearningRobustContent",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/DigitalLearning/DigitalLearningRobustContentSection"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDataGetServiceCategories = createAsyncThunk(
  "services/digitalLearning/GetServiceCategories",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/DigitalLearning/GetServiceCategories"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  banner: [],
  digitalContent: [],
  digitalLearningTeams: [],
  digitalLearningTeamsContent: [],
  DigitalLearningRobustContent: [],
  getServiceCategories:[],
  isLoading: false,
  error: null,
};

export const digitalLearning = createSlice({
  name: "digitalLearning",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataDigitalContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataDigitalContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.digitalContent = action.payload;
      })
      .addCase(getDataDigitalContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataDigitalLearningTeams.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataDigitalLearningTeams.fulfilled, (state, action) => {
        state.isLoading = false;
        state.digitalLearningTeams = action.payload;
      })
      .addCase(getDataDigitalLearningTeams.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataDigitalLearningTeamContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataDigitalLearningTeamContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.digitalLearningTeamsContent = action.payload;
      })
      .addCase(getDataDigitalLearningTeamContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataDigitalLearningRobustContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataDigitalLearningRobustContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.DigitalLearningRobustContent = action.payload;
      })
      .addCase(getDataDigitalLearningRobustContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataGetServiceCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataGetServiceCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getServiceCategories = action.payload;
      })
      .addCase(getDataGetServiceCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default digitalLearning.reducer;
