import { lazy } from "react";
const Banner = lazy(() => import("./components/banner/Banner"));
const AppliedTechnologySchoolContent = lazy(() => import("./components/appliedTechnologySchoolContent/AppliedTechnologySchoolContent"));
const Contact = lazy(() => import("./components/contact/Contact"));


const AppliedTechnologySchool = () => {


  return (
    <> 
      <Banner />
      <AppliedTechnologySchoolContent />
      <Contact />
    </>
  );
};

export default AppliedTechnologySchool;
