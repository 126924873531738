import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataCategoriesServices } from "../../../../../app/solutions/vocationalEducation/vocationalEducationSlice";
import { useTranslation } from "react-i18next";
import InternalSection from "../../../../../components/internalSection/InternalSection";
import LineBox from "../../../../../components/boxes/lineBox/LineBox";

function Technical() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, categoriesServices, error } = useSelector((state) => state.vocationalEducation);
  useEffect(() => {
    dispatch(getDataCategoriesServices());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <section className="Technical mainBgColor pb-3 mb-4" id="technical-schools-management-and-operations">
      <Container>
        {categoriesServices?.result?.services.slice(3, 4)?.map((offer) => {
          return offer?.serviceCategoryServicesContents?.map((section) => {
            return (
              <InternalSection
                title={`${i18n.language === "en" ? section?.titleEn : section?.titleAr}`}
                img={section?.image}
                desc={`${i18n.language === "en" ? section?.descriptionEn : section?.descriptionAr}`}
                key={section.id}
              />
            );
          });
        })}

        <div className="services">
          {categoriesServices?.result?.services.slice(3, 4)?.map((offer) => {
            return offer?.serviceCategoryServicesContents?.map((section) => {
              return section?.serviceContentListDetails?.map((desc) => {
                return (
                  <>
                    <p>{i18n.language === "en" ? desc?.titleEn : desc?.titleAr}</p>
                    <Row>
                      {desc?.serviceCategoryServiceContentLists?.map((box) => {
                        return (
                          <Col lg={6} key={box.id} className="mb-4">
                            <LineBox
                              title={`${i18n.language === "en" ? box?.titleEn : box?.titleAr}`}
                              desc={`${i18n.language === "en" ? box?.descriptionEn : box?.descriptionAr}`}
                              pattern="/blueLine.png"
                              dots={true}
                              blacktitle={true}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                );
              });
            });
          })}
        </div>
      </Container>
    </section>
  );
}

export default Technical;
