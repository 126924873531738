
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Error from "../../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../../components/utils/portal/loading/Loading";
import { getDataServices } from "../../../../../../app/solutions/k12/k12Slice";
import { useTranslation } from "react-i18next";
import InternalSection from "../../../../../../components/internalSection/InternalSection";
import Img from "../../../../../../components/utils/img/Img";

function Digitization() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, services, error } = useSelector((state) => state.k12);
  useEffect(() => {
    dispatch(getDataServices());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <section className="Digitization mainBgColor pb-0 mb-0" id="services-digitization-of-content">
      <Container>
        {services?.result?.services.slice(3, 4)?.map((offer) => {
          return offer?.serviceCategoryServicesContents?.map((section) => {
            return (
              <>
                <InternalSection
                  title={`${i18n.language === "en" ? section?.titleEn : section?.titleAr}`}
                  img={section?.image}
                  desc={`${i18n.language === "en" ? section?.descriptionEn : section?.descriptionAr}`}
                  key={section.id}
                />

                <div className="services">

                  {section?.serviceContentListDetails?.map((desc) => (
                    <>
                      <h3 className="mb-4">{`${i18n.language === "en" ? desc?.titleEn : desc?.titleAr}`}</h3>

                      {/* <p>{i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr}</p> */}
                      
                      <p  dangerouslySetInnerHTML={{__html: i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr}} />

                      
                      <Row className="w-90 mb-3">
                        {desc?.serviceCategoryServiceContentLists?.map((box) => (
                          <Col lg={3} md={6} className="mb-4" key={box.id}>
                            <div className="logoBox p-3">
                              <Img src={box?.image} alt={i18n.language === "en" ? box?.titleEn : box?.titleAr} className="img-fluid mb-2 icon" />
                              <h5>{`${i18n.language === "en" ? box?.titleEn : box?.titleAr}`}</h5>
                            </div>
                          </Col>
                        ))}
                      </Row>

                    </>
                  ))}


                </div>


              </>
            );
          });
        })}



      </Container>
    </section>
  );
}

export default Digitization;
