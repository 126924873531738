import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../../components/utils/portal/loading/Loading";
import { getDataServices } from "../../../../../../app/solutions/higherEducation/higherEducationSlice";
import { useTranslation } from "react-i18next";
import HalfSection from "../../../../../../components/halfSection/HalfSection";

function Offer() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, services, error } = useSelector((state) => state.higherEducation);
  useEffect(() => {
    dispatch(getDataServices());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="Offer pb-0" >
      {services?.result?.services?.map((offer) => {
        return offer?.serviceCategoryServicesContents?.map((section) => {
          return (
            <div className="evenOddSection3 mb-0 pb-lg-5 pt-5" key={section.id}>
              <Container>
                <HalfSection
                  id={section.id}
                  title={`${i18n.language === "en" ? section.titleEn : section.titleAr}`}
                  desc={`${i18n.language === "en" ? section.descriptionEn : section.descriptionAr}`}
                  img={section.image}
                  className={" mb-lg-0 mb-4"}
                  col1Lg={7}
                  col2Lg={5}
                  transparent={true}
                  ptb0={true}
                  psOdd={true}
                  href={section.titleEn}
                />
              </Container>
            </div>
          );
        });
      })}
    </section>
    
  );
}

export default Offer;
