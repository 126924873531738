import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDataBanner = createAsyncThunk("services/vocationalEducation/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get(
      "https://backend.nahdetmisr.com:10443/api/OferringVocationalEducation/OfferingHeadSection"
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataVocational = createAsyncThunk(
  "services/vocationalEducation/getDataVocational",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/OferringVocationalEducation/GetOfferingFirstSection"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDataCategoriesServices = createAsyncThunk(
  "services/vocationalEducation/getDataCategoriesServices",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        "https://backend.nahdetmisr.com:10443/api/OferringVocationalEducation/GetOfferingCategoriesServices"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  banner: [],
  vocational: [],
  categoriesServices: [],
  isLoading: false,
  error: null,
};

export const vocationalEducation = createSlice({
  name: "vocationalEducation",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataVocational.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataVocational.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vocational = action.payload;
      })
      .addCase(getDataVocational.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataCategoriesServices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataCategoriesServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoriesServices = action.payload;
      })
      .addCase(getDataCategoriesServices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default vocationalEducation.reducer;
